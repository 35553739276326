.boxPrestapolisDEV {
  min-height: 30px;
background: rgb(188, 162, 0);
  background: radial-gradient(
    circle,
    rgba(188, 162, 0, 1) 0%,
    rgba(188, 162, 0, 1) 100%
  );
}

.boxPrestapolis {
  min-height: 30px;
  background: rgb(43, 174, 179);
  background: radial-gradient(
    circle,
    rgba(43, 174, 179, 1) 0%,
    rgba(43, 174, 179, 1) 100%
  );
}



.boxPrestapolisQA {
  min-height: 30px;
  background: rgb(77, 75, 70);
  background: radial-gradient(circle, rgb(32, 30, 30) 0%, rgb(46, 43, 43) 100%);
}

.boxPrestapolisSTG {
  min-height: 30px;
  background: rgb(21, 141, 27);
  background: radial-gradient(
    circle,
    rgba(21, 141, 27, 1) 0%,
    rgba(21, 141, 27, 1) 100%
  );
}

.logoPrestapolis {
  margin-left: -130px;
  margin-bottom: 20px;
}

.logoPicture {
  max-width: 400px;
  max-height: 150px;
  align-items: center;
  margin-left: 0%;
}

.logoCentral{
  margin-right: 60px; 
  margin-top: -10px; 
  margin-bottom: -50px;
}


.logoCentralSad{
  margin-right: 60px; 
  margin-top: -10px; 
  margin-bottom: -50px;
}

.logoSad {
  width: 10rem;
  margin-left: 38%;
  margin-bottom: 20px;
}

.logoSad2 {
  width: 5rem;
  margin-left: 39%;
  margin-bottom: -15px;
}

.logoPromise {
  width: 8rem;
  margin-left: 32%;
  margin-bottom: -15px;
}

.imgLogo {
  position: relative;
  max-width: 100%;
  margin-top: -5%;
  width: 250px;
  margin-left: 15pt;
}

.modalDocumentValidation {
  margin: 0 auto;
  width: 70%;
  max-width: 375px;
  margin-top: 120px;
  border-radius: 20px;
  background-color: white;
  border: 2px soild #000;
  box-shadow: 5px 5px 10px black;
  padding: 15px 28px 24px;
}

.btn-cellphone {
  color: #ffffff !important;
  box-shadow: none;
  background-color: #2BAEB3 !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
  text-transform: none !important;
  width: 20rem;
  border-radius: 100px !important;
  margin-bottom: 10pt !important;
  margin-left: 10pt !important;
}

.content-modal {
  align-items: center;
  background-color: rgba(0,0,0,.5);
  display: flex;
  flex-direction: column;
  font-family: var(--text-font);
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.bQ_sN {
  top: var(--navbar-height);
}

.bQ_sK {
  align-items: center;
  text-align: center;
  background: #fff;
  box-shadow: 0 3px 6px #00000029;
  justify-content: flex-end;
  min-height: 4rem;
  padding: 0.2rem 1rem;
  z-index: 20;
  width: 350px;
  font-size: 10pt;
}

.bQ_qp, .bQ_sK {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
}

*, :after, :before {
  box-sizing: inherit;
}

.bQ_jQ {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.bQ_jQ, .bQ_sN {
  justify-content: flex-start;
}

.bQ_jQ {
  position: relative;
}

.bQ_jP {
  -o-object-fit: cover;
  object-fit: cover;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  width: 510px;
  aspect-ratio: auto 510 / 490;
  height: 490px;
}

.bQ_sL {
    left: 50%;
    position: absolute;
    top: 40%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    -webkit-transform-origin: 0 80%;
    transform-origin: 0 80%;
    transition: -webkit-transform .5s ease-in;
    transition: transform .5s ease-in;
    transition: transform .5s ease-in,-webkit-transform .5s ease-in;
    width: 14%;
    z-index: 20;
}

.bQ_sL2 {
  left: 50%;
  position: absolute;
  top: 40%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  -webkit-transform-origin: 0 80%;
  transform-origin: 0 80%;
  transition: -webkit-transform .5s ease-in;
  transition: transform .5s ease-in;
  transition: transform .5s ease-in,-webkit-transform .5s ease-in;
  filter: invert(4) sepia(1) saturate(119) hue-rotate(-13deg);
  width: 14%;
  z-index: 20;
}

img {
    border-style: none;
    margin: 0;
    max-width: 100%;
    padding: 0;
}

audio, canvas, progress, video {
  display: inline-block;
}

.bQ_sM {
    background: #88ce9c;;
    border-radius: 50%;
    height: 1rem;
    left: 50%;
    position: absolute;
    top: 40%;
    transition: -webkit-transform .5s ease-in;
    transition: transform .5s ease-in;
    transition: transform .5s ease-in,-webkit-transform .5s ease-in;
    width: 1rem;
    z-index: 20;
}

.bQ_sP {
  background: #fff;
  border-radius: 0.5rem 0.5rem 0 0;
  bottom: 0;
  box-shadow: 0 3px 6px #00000029;
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  left: 5%;
  top: -3.3%;
  padding: 0.5rem 1rem;
  position: relative;
  transform: translate(-50%);
  z-index: 25;
}

.bQ_sP2 {
  background: #fff;
  border-radius: 0.5rem 0.5rem 0 0;
  bottom: 0;
  box-shadow: 0 3px 6px #00000029;
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  left: 50%;
  top: -5.4%;
  padding: 2rem 2rem;
  position: relative;
  transform: translate(-50%);
  z-index: 25;
}

.bQ_sP3 {
  background: #fff;
  align-items: center;
  border-radius: 0.5rem 0.5rem 0 0;
  bottom: 0;
  box-shadow: 0 3px 6px #00000029;
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  left: 50%;
  top: 1%;
  padding: 1rem 1rem;
  position: relative;
  transform: translate(-50%);
  z-index: 25;
}

.bQ_sP4 {
  background: #fff;
  align-items: center;
  border-radius: 0.5rem 0.5rem 0 0;
  bottom: 0;
  box-shadow: 0 3px 6px #00000029;
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  left: 0%;
  top: 1%;
  padding: 1rem 1rem;
  position: relative;
  z-index: 25;
}

.letraPhoto{
  width: 420px;
  text-align: center;
  font-weight: bold;
}

.letraPhoto2{
  width: 420px;
  text-align: center;
  font-weight: bold;
}

.botonPhotos{
  color: #2BAEB3;
  border-radius: 50%;
  height: 100% !important;
  left: 1%;
  position: absolute;
  top: 2%;
  transition: transform .5s ease-in;
  width: 100% !important;
  z-index: 22;
}

.botonPhotos2{
  color: #707070;
  border-radius: 50%;
  height: 100% !important;
  left: 1%;
  position: absolute;
  top: 2%;
  transition: transform .5s ease-in;
  width: 100% !important;
  z-index: 22;
}
@media (max-width: 1024px){
  .bQ_sL {

    left: 50%;
    position: absolute;
    top: 30%;
    transform: translate(-50%,-50%);
    transform-origin: 0 80%;
    transition: transform .5s ease-in;
    width: 20%;
    z-index: 20;

}

.bQ_sL2 {
  left: 50%;
  position: absolute;
  top: 40%;
  transform: translate(-50%,-50%);
  transform-origin: 0 80%;
  transition: transform .5s ease-in;
  width: 20%;
  z-index: 20;
}

img {
    border-style: none;
    margin: 0;
    max-width: 100%;
    padding: 0;
}

audio, canvas, progress, video {
  display: inline-block;
}

.bQ_sM {
    background: #88ce9c;;
    border-radius: 50%;
    height: 1rem;
    left: 50%;
    position: absolute;
    top: 40%;
    transition: -webkit-transform .5s ease-in;
    transition: transform .5s ease-in;
    transition: transform .5s ease-in,-webkit-transform .5s ease-in;
    width: 1rem;
    z-index: 20;
}

.botonPhotos{
  color: #2BAEB3;
  border-radius: 50%;
  height: 100% !important;
  left: 1%;
  position: absolute;
  top: 2%;
  transition: transform .5s ease-in;
  width: 100% !important;
  z-index: 22;
}

.botonPhotos2{
  color: #707070;
  border-radius: 50%;
  height: 100% !important;
  left: 1%;
  position: absolute;
  top: 2%;
  transition: transform .5s ease-in;
  width: 100% !important;
  z-index: 22;
}
}

@media (max-width: 1280px){
  .bQ_sL {
    left: 50%;
    position: absolute;
    top: 40%;
    transform: translate(-50%,-50%);
    transform-origin: 0 80%;
    transition: transform .5s ease-in;
    width: 18%;
    z-index: 20;

}

.bQ_sL2 {
  left: 50%;
  position: absolute;
  top: 40%;
  transform: translate(-50%,-50%);
  transform-origin: 0 80%;
  transition: transform .5s ease-in;
  width: 18%;
  z-index: 20;
}

img {
    border-style: none;
    margin: 0;
    max-width: 100%;
    padding: 0;
}

audio, canvas, progress, video {
  display: inline-block;
}

.bQ_sM {
    background: #88ce9c;;
    border-radius: 50%;
    height: 1rem;
    left: 50%;
    position: absolute;
    top: 40%;
    transition: -webkit-transform .5s ease-in;
    transition: transform .5s ease-in;
    transition: transform .5s ease-in,-webkit-transform .5s ease-in;
    width: 1rem;
    z-index: 20;
}

.botonPhotos{
  color: #2BAEB3;
  border-radius: 50%;
  height: 100% !important;
  left: 1%;
  position: absolute;
  top: 2%;
  transition: transform .5s ease-in;
  width: 100% !important;
  z-index: 22;
}

.botonPhotos2{
  color: #707070;
  border-radius: 50%;
  height: 100% !important;
  left: 1%;
  position: absolute;
  top: 2%;
  transition: transform .5s ease-in;
  width: 100% !important;
  z-index: 22;
}
}

@media (max-width: 820px){
.bQ_sP2 {
    background: #fff;
    border-radius: 0.5rem 0.5rem 0 0;
    bottom: 0;
    box-shadow: 0 3px 6px #00000029;
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    left: 50%;
    top: -11% !important;
    padding: 2rem 2rem;
    position: relative;
    transform: translate(-50%);
    z-index: 25;
}

.bQ_sL {
  left: 50%;
  position: absolute;
  top: 28% !important;
  transform: translate(-50%,-50%);
  transform-origin: 0 80%;
  transition: transform .5s ease-in;
  width: 20%;
  z-index: 20;
}

.bQ_sL2 {
  left: 50%;
  position: absolute;
  top: 28% !important;
  transform: translate(-50%,-50%);
  transform-origin: 0 80%;
  transition: transform .5s ease-in;
  width: 20%;
  z-index: 20;
}

.bQ_sM {
  background: #88ce9c;
  border-radius: 50%;
  height: 1rem;
  left: 48.7%;
  position: absolute;
  top: 28% !important;
  transition: transform .5s ease-in;
  width: 1rem;
  z-index: 20;
}

}

@media (max-width: 896px){

  .bQ_sP3 {
    background: #fff;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    bottom: 0;
    box-shadow: 0 3px 6px #00000029;
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    left: 50%;
    top: 1%;
    padding: 1rem 1rem;
    position: relative;
    transform: translate(-50%);
    z-index: 25;
  }

  .letraPhoto {
    width: 420px;
    text-align: center;
    font-weight: bold;
    font-size: 12pt;
  }

  
  .bQ_sP2 {
    background: #fff;
    border-radius: 0.5rem 0.5rem 0 0;
    bottom: 0;
    box-shadow: 0 3px 6px #00000029;
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    left: 50%;
    top: -41%;
    padding: 2rem 2rem;
    position: relative;
    transform: translate(-50%);
    z-index: 25;
  }

  .bQ_sL {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    transform-origin: 0 80%;
    transition: transform .5s ease-in;
    width: 20%;
    z-index: 20;
  }

  .bQ_sL2 {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    transform-origin: 0 80%;
    transition: transform .5s ease-in;
    width: 20%;
    z-index: 20;
  }

img {
    border-style: none;
    margin: 0;
    max-width: 100%;
    padding: 0;
}

audio, canvas, progress, video {
  display: inline-block;
}

.bQ_sM {
  background: #88ce9c;
  border-radius: 50%;
  height: 1rem;
  left: 48.7%;
  position: absolute;
  top: 50%;
  transition: transform .5s ease-in;
  width: 1rem;
  z-index: 20;
}

.botonPhotos{
  color: #2BAEB3;
  border-radius: 50%;
  height: 100% !important;
  left: 1%;
  position: absolute;
  top: 2%;
  transition: transform .5s ease-in;
  width: 100% !important;
  z-index: 22;
}

.botonPhotos2{
  color: #707070;
  border-radius: 50%;
  height: 100% !important;
  left: 1%;
  position: absolute;
  top: 2%;
  transition: transform .5s ease-in;
  width: 100% !important;
  z-index: 22;
}
}

@media (max-width: 320px) { 
  .bQ_sL {
    left: 50% !important;
    position: absolute;
    top: 50% !important;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    -webkit-transform-origin: 0 80%;
    transform-origin: 0 80%;
    transition: -webkit-transform .5s ease-in;
    transition: transform .5s ease-in;
    transition: transform .5s ease-in,-webkit-transform .5s ease-in;
    width: 45% !important;
    z-index: 20;
}

.bQ_sL2 {
  left: 50% !important;
  position: absolute;
  top: 50% !important;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  -webkit-transform-origin: 0 80%;
  transform-origin: 0 80%;
  transition: -webkit-transform .5s ease-in;
  transition: transform .5s ease-in;
  filter: invert(4) sepia(1) saturate(119) hue-rotate(-13deg);
  transition: transform .5s ease-in,-webkit-transform .5s ease-in;
  width: 45% !important;
  z-index: 20;
}


img {
    border-style: none;
    margin: 0;
    max-width: 100%;
    padding: 0;
}

audio, canvas, progress, video {
  display: inline-block;
}

.bQ_sM {
    background: #88ce9c;;
    border-radius: 50%;
    height: 1rem;
    left: 50%;
    position: absolute;
    top: 45% !important;
    transition: -webkit-transform .5s ease-in;
    transition: transform .5s ease-in;
    transition: transform .5s ease-in,-webkit-transform .5s ease-in;
    width: 1rem;
    z-index: 20;
}

.bQ_sP2 {
  background: #fff;
  border-radius: 0.5rem 0.5rem 0 0;
  bottom: 0;
  box-shadow: 0 3px 6px #00000029;
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  left: 50%;
  top: -12.2% !important;
  padding: 2rem 2rem;
  position: relative;
  transform: translate(-50%);
  z-index: 25;
}

.letraPhoto {
  width: 270px;
  margin-left: 60pt;
  text-align: center;
  font-size: 11pt;
  justify-content: center;
}

.bQ_jP {
  object-fit: cover;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  width: 470px;
  aspect-ratio: auto 510 / 490;
  height: 410px;
}

.bQ_sP3 {
  background: #fff;
  align-items: center;
  border-radius: 0.5rem 0.5rem 0 0;
  bottom: 0;
  box-shadow: 0 3px 6px #00000029;
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  left: 50%;
  top: 1%;
  padding: 1rem 1rem;
  position: relative;
  transform: translate(-50%);
  z-index: 25;
}

}

@media (max-width: 414px) { 

  .logoSad {
    width: 160px;
    max-height: 375px;
    margin-left: 35%;
  }

  .logoPromise {
    width: 5rem;
    margin-left: 32%;
    margin-bottom: -15px;
  }

  .logoPrestapolis {
    margin-top: -20pt;
    margin-left: 40px;
    margin-bottom: 20px;
  }

  .letraPhoto{
    width: 270px;
    margin-left: 60pt;
    text-align: center;
    font-size: 11pt;
    justify-content: center;
  }

  .letraPhoto2{
    width: 270px;
    text-align: center;
    font-size: 15pt;
    justify-content: center;
  }

  .logoCentralSad{
    margin-right: 80px; 
    margin-top: -10px; 
    margin-bottom: -50px;
  }

  .logoPicture {
    margin-left: 0% !important;
  }

  .bQ_sP2 {
  background: #fff;
  border-radius: 0.5rem 0.5rem 0 0;
  bottom: 0;
  box-shadow: 0 3px 6px #00000029;
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  left: 50%;
  top: -5.4%;
  padding: 2rem 2rem;
  position: relative;
  transform: translate(-50%);
  z-index: 25;
  }

  .bQ_sP {
    background: #fff;
    border-radius: 0.5rem 0.5rem 0 0;
    bottom: 0;
    box-shadow: 0 3px 6px #00000029;
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    left: 15%;
    top: -6.2%;
    padding: 0.5rem 1rem;
    position: relative;
    transform: translate(-50%);
    z-index: 25;
  }

  .bQ_sL {
    left: 50%;
    position: absolute;
    top: 45% !important;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    -webkit-transform-origin: 0 80%;
    transform-origin: 0 80%;
    transition: -webkit-transform .5s ease-in;
    transition: transform .5s ease-in;
    transition: transform .5s ease-in,-webkit-transform .5s ease-in;
    width: 50%;
    z-index: 20;
}

.bQ_sL2 {
  left: 50%;
  position: absolute;
  top: 45% !important;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  -webkit-transform-origin: 0 80%;
  transform-origin: 0 80%;
  transition: -webkit-transform .5s ease-in;
  filter: invert(4) sepia(1) saturate(119) hue-rotate(-13deg);
  transition: transform .5s ease-in;
  transition: transform .5s ease-in,-webkit-transform .5s ease-in;
  width: 50%;
  z-index: 20;
}

img {
    border-style: none;
    margin: 0;
    max-width: 100%;
    padding: 0;
}

audio, canvas, progress, video {
  display: inline-block;
}

.botonPhotos{
  color: #2BAEB3;
  border-radius: 50%;
  height: 100% !important;
  left: 1%;
  position: absolute;
  top: 2%;
  transition: transform .5s ease-in;
  width: 100% !important;
  z-index: 22;
}

.botonPhotos2{
  color: #707070;
  border-radius: 50%;
  height: 100% !important;
  left: 1%;
  position: absolute;
  top: 2%;
  transition: transform .5s ease-in;
  width: 100% !important;
  z-index: 22;
}

.bQ_sM {
    background: #88ce9c;;
    border-radius: 50%;
    height: 1rem;
    left: 50%;
    position: absolute;
    top: 45% !important;
    transition: -webkit-transform .5s ease-in;
    transition: transform .5s ease-in;
    transition: transform .5s ease-in,-webkit-transform .5s ease-in;
    width: 1rem;
    z-index: 20;
}

  .btn-cellphone {
    color: #ffffff !important;
    box-shadow: none;
    background-color: #2BAEB3 !important;
    -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
    text-transform: none !important;
    width: 20rem;
    border-radius: 100px !important;
    margin-bottom: 10pt !important;
    margin-left: 10pt !important;
  }
  
  .textContenidoCell{
    position: relative;
    max-width: 89% !important;
    color: #707070;
    font-size: 9pt !important;
  }

  .modalDocumentValidation {
    margin: 0 auto;
    width: 65%;
    max-width: 375px;
    margin-top: 4px;
    border-radius: 20px;
    background-color: white;
    border: 2px soild #000;
    box-shadow: 5px 5px 10px black;
    padding: 15px 28px 24px;
  }


  .textContenidoCell{
    position: relative;
    max-width: 89% !important;
    color: #707070;
    font-size: 9pt !important;
  }

  .textContenidoApproved3{
    position: relative;
    max-width: 89% !important;
    margin-left: 1em !important;
    color: #707070;
    font-size: 9pt !important;
  }

  .textContenidoApproved4{
    position: relative;
    max-width: 89% !important;
    margin-left: 1em !important;
    color: #707070;
    font-size: 15pt !important;
  }


  .textMiddleFirm{
    position: relative;
    max-width: 89% !important;
    margin-left: 1em !important;
    color: #2BAEB3!important;
    font-size: 11pt !important;
    cursor: pointer;
    text-decoration: none;
    text-transform: inherit!important;
  }

  .textMiddleFirm2{
    position: relative;
    max-width: 89% !important;
    margin-left: 1em !important;
    color: #2BAEB3;
    font-size: 15pt !important;
  }

  .textModalAprovate{
    position: relative;
    max-width: 89% !important;
    margin-left: 1em !important;
    color: #2BAEB3;
    font-size: 13pt !important;
  }

  .textModalAprovateLink{
    position: relative;
    max-width: 89% !important;
    margin-left: 0em !important;
    color: #2BAEB3;
    font-size: 13pt !important;
  }

  .textInicioFirm{
    position: relative;
    max-width: 89%;
    margin-left: 12pt !important;
    color: #707070;
    font-size: 14pt !important;
  }

  .textClausulas2{
    position: relative;
    max-width: 89%;
    margin-left: 1.1em !important;
    color: #ffffff;
    font-size: 9.5pt !important;
  }

  .clausulasApproved {
    background-color: #ffffff;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-right: 6px !important;
    padding: 0px;
    border-radius: 10pt;
    -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
    width: 100% !important;
  }

}

.textInicioFirm{
  position: relative;
  max-width: 89%;
  margin-left: 17pt;
  color: #707070;
  font-size: 18pt;
}

.textTokenUsed{
  position: relative;
  max-width: 89%;
  margin-left: 1.6em;
  color: #707070;
  font-size: 16pt;
  text-align: center;
}

.textContenidoFirm{
  position: relative;
  max-width: 89%;
  color: #707070;
  font-size: 11pt;
  text-align: justify;
}

.textContenidoCellEnd{
  position: relative;
  max-width: 89%;
  margin-left: 1em;
  color: #707070;
  font-size: 9pt;
  text-align: center;
}

.textContenidoApproved3{
  position: relative;
  max-width: 200%;
  margin-left: 1em;
  color: #707070;
  font-size: 13pt;
  text-align: center;
}

.textContenidoApproved4{
  position: relative;
  max-width: 200%;
  margin-left: 1em;
  color: #707070;
  font-size: 20pt;
  text-align: center;
}

.textMiddleFirm{
  position: relative;
  max-width: 89%;
  margin-left: 1em !important;
  color: #2BAEB3!important;
  font-size: 13pt;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  text-transform: inherit!important;
}

.textMiddleFirm2{
  position: relative;
  max-width: 89%;
  margin-left: 1.5em;
  color: #2BAEB3;
  font-size: 13pt;
  text-align: center;
}

.textModalAprovate{
  position: relative;
  max-width: 89%;
  margin-left: 15pt;
  color: #2BAEB3;
  font-size: 13pt;
  text-align: center;
}

.textModalAprovateLink{
  position: relative;
  max-width: 89%;
  margin-left: 15pt;
  color: #2BAEB3;
  font-size: 13pt;
  text-align: center;
}

.clausulasApproved {
  background-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 26px;
  margin-left: 1px;
  padding: 0px;
  border-radius: 10pt;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
  width: 100%;
}

.puntoFirm {
  color: #f29f05; 
  font-size: 0.7em !important;
  margin-top: -10pt;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.textClausulas2{
  margin-top: -5pt;
  margin-bottom: 1pt;
  position: relative;
  max-width: 89%;
  margin-left: 1em;
  color: #ffffff;
  font-size: 12pt;
  text-align: justify;
}

.linksWhatsapp {
  color: #24b611;
  display: inline-block;
}

.marginReject{
  max-width: 440px;
  padding: 20px;
  margin-bottom: 25px;
  margin-left: 25px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 10px 10px 5px 0px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
}