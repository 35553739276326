/**********File Inputs**********/
html {
  margin: 0px;
  padding: 0px;
  border: 0px;
  height: 0px;
}
.container-input {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 4px;
}

.boxPrestapolisDEV {
  min-height: 30px;
background: rgb(188, 162, 0);
  background: radial-gradient(
    circle,
    rgba(188, 162, 0, 1) 0%,
    rgba(188, 162, 0, 1) 100%
  );
}

.boxPrestapolis {
  min-height: 30px;
  background: rgb(43, 174, 179);
  background: radial-gradient(
    circle,
    rgba(43, 174, 179, 1) 0%,
    rgba(43, 174, 179, 1) 100%
  );
}

.boxPrestapolisQA {
  min-height: 30px;
  background: rgb(77, 75, 70);
  background: radial-gradient(circle, rgb(32, 30, 30) 0%, rgb(46, 43, 43) 100%);
}

.boxPrestapolisSTG {
  min-height: 30px;
  background: rgb(21, 141, 27);
  background: radial-gradient(
    circle,
    rgba(21, 141, 27, 1) 0%,
    rgba(21, 141, 27, 1) 100%
  );
}

#frontSideDocument {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
}

#backSideDocument {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.inputfile + label {
  max-width: 100%;
  font-weight: 700px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 0.31rem;
}

.inputfile + label svg {
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

.iborrainputfile {
  font-size: 0pt;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif !important;
  margin-bottom: 5px;
}

/* style 2 */

.inputfile-2 + label {
  color: #ffffff;
  border: 2px solid currentColor;
  background: #2BAEB3;
  display: flex;
}

.color-front + label {
  color: #ffffff;
}

.inputfile-2:focus + label,
.inputfile-2 + label:active {
  color: #2BAEB3;
}

.creditAppForm {
  height: 100vh;
  background: #0099bc;
  background: radial-gradient(
    circle,
    rgba(0, 153, 188, 1) 0%,
    rgba(0, 153, 188, 1) 100%
  );
}
h5 {
  font-size: 14pt;
}
h4 {
  color: #2BAEB3;
  font-size: 20pt;
  padding: 5px 0px;
}
h6 {
  font-size: 11pt;
  padding-bottom: 15px;
}
.col-12 .form-group .my-auto {
  font-size: 10pt;
}
.hidden-info {
  display: none;
}
.show-info {
  background-color: #2BAEB3;
  text-align: center;
}

.form-field-Prestapolis {
  padding: 8px 5px 8px 40px;
  border-radius: 0;
  border-bottom: 0px solid #e5e5e5;
}
.interes-cero-selected {
  display: none;
  font-size: calc(1em + 0.5vw);
}
.card-aproved-text {
  font-size: calc(0.75em + 0.5vw);
}
.lable-agrega {
  font-size: calc(0.4em + 0.7vw);
}
.input-calcula {
  font-size: calc(0.7em + 0.6vw);
}
.text-planes {
  font-size: calc(0.8em + 0vw);
}
#calculadora {
  margin-top: 20%;
}
#terminos-p {
  text-align: center;
  font-size: 9pt !important;
  margin-right: auto;
}

.terminoServicio {
  text-align: justify;
  font-size: 9pt !important;
  margin-right: auto;
}

.form-field-Prestapolis-selected {
  border-radius: 0;
  border: 5px solid #2BAEB3 !important;
}

.form-field-dir-Prestapolis {
  padding: 8px 5px;
  border-radius: 0;
  border: 1px solid #e5e5e5;
}
.form-dropdown-Prestapolis {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  padding-left: 16px;
  font-size: 14px;
  /*background: url(./../img/arrow_down.png);*/
  background-repeat: no-repeat;
  background-position: 95%;
}
.icons-form-Prestapolis {
  z-index: 1;
  margin-left: 10px;
  margin-top: 30px;
}
.fa {
  color: #f4f4f4;
  font-size: 1.15rem;
}
.uploads-Prestapolis > .valid-feedback-Prestapolis {
  color: #28a745;
}
.checkbox-Prestapolis {
  height: 20px;
}
.valid-feedback,
.invalid-feedback {
  width: auto;
  margin-left: 40px;
  margin-top: 0px;
}
.valid-feedback-Prestapolis {
  width: auto;
  margin-left: 40px;
  margin-top: 0px;
}
.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-confirm {
  margin: auto;
  max-width: 440px;
}
.btn-siguiente {
  background-color: #f29f05;
  border-radius: 10px;
  border: 0px;
  color: white;
  width: 100%;
  font-size: 14pt;
  margin: 10px 0px;
}
.btn-siguiente:hover {
  /*background-color: rgba(41, 87, 161, 0.9);*/
  color: #fff;
}
.btn-volver {
  background-color: #676767;
  border-radius: 10px;
  border: 0px;
  color: white;
  width: 100%;
  font-size: 14pt;
  margin: 10px 0px;
}
.btn-volver:hover {
  /*background-color: rgba(41, 87, 161, 0.9);*/
  color: #fff;
}
/*.bnt-section {
	 background-color: rgba(41, 87, 161, 0);
}*/
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #495057;
  border: 0px;
}
/*.btn-atras {
	/*margin: 16px 0 10px 0;
	
}*/
.btn-atras button {
  background-color: #676767;
}
.text-white {
  color: #707070;
  font-size: 20px;
  text-align: center;
  margin-left: 20px;

  font-family: 'Montserrat Alternates SemiBold', sans-serif !important;
}

.btn-calcular {
  text-transform: none;
  width: inherit;
  background-color: #2BAEB3;
  border-radius: 100px;
}

.MuiFormGroup-root {
  flex-direction: row !important;
}

.radioButon{
  text-align: right !important; 
  margin-bottom:20px !important; 
  margin-top:1px !important;
  margin-left: 96pt !important; 
  display: row !important;
}

.textPreguntas{
  text-align: justify;
  font-size: 14px;
  color:#707070;
  margin-top:3px;
  margin-left: 4pt;
}

.textPreguntas2{
  text-align: justify;
  font-size: 14px;
  color:#707070;
  margin-top:3px;
  margin-left: 40pt;
}

.MuiTypography-body1 {

  font-size: 12pt !important;
  font-weight: 1000 !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: #707070 !important;
}

.logo {
  margin-left: 45px;
  margin-bottom: 20px;
}

.logo img {
  position: relative;
  max-width: 100%;
  margin-top: -5%;
  width: 250px;
  margin-left: 15pt;
}

.logo2 {
  max-width: 200px;
  max-height: 100px;
  align-items: center;
  margin-left: -30px;
  margin-bottom: 20px;
  filter: invert(1.5) sepia(1) saturate(5) hue-rotate(175deg);
}

.logoNotificacion {
  max-width: 200px;
  max-height: 100px;
  align-items: center;
}

.logoNotificacion2 {
  max-width: 200px;
  max-height: 100px;
  align-items: center;
  margin-left: 12pt;
  margin-bottom: -20px;
}

.logoPicture2 {
  max-width: 150px;
  max-height: 150px;
  align-items: center;
  margin-left: 0%;
}

.titleNotificacion {
  margin-left: 33pt;
  margin-bottom: -20px;
  color: #2BAEB3;
}

.textNotificacion {
  margin-left: 25pt;
}

.logoDocument {
  max-width: 200px;
  max-height: 100px;
  align-items: center;
  margin-bottom: -20px;
}

.logoCedulas {
    max-width: 200px;
    max-height: 100px;
    align-items: center;
    margin-bottom: 20px;
}

.logoCellphone {
  max-width: 200px;
  max-height: 100px;
  align-items: center;
  margin-bottom: -20px;
}


.confirm #title {
  margin-bottom: 10px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
.confirm #title .title-text {
  font-size: 16pt;
}
/* .confirm .bnt-section {
	/* background-color: rgba(41, 87, 161, 0);
}*/
.row {
  margin-right: 0px;
  margin-left: 0px;
}

.confirm .row {
  margin-right: 0px;
  margin-left: 0px;
}
.confirm .col-10 .col-2 {
  padding: 0;
}
.confirm section {
  padding: 20px;
  margin-bottom: 25px;
  margin-left: 25px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
.confirm label {
  font-family: 'Montserrat', sans-serif !important;
}

.MuiSlider-rail {
  color: #ffffff;
  height: 5pt !important;
  border-radius: 100%;
  border: aliceblue;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
}

.MuiSlider-track {
  height: 7px !important;
  background-color: #2BAEB3 !important;
}

.MuiSlider-thumb {
  width: 25px !important;
  height: 25px !important;
  margin-top: -8px !important;
  margin-left: -12px !important;
  background-color: #f29f05 !important;
}

.PrivateValueLabel-offset-25 {
  color: #2BAEB3;
  margin-left: 10pt;
}

.PrivateValueLabel-label-27 {
  color: rgb(255 255 255 / 87%) !important;
}

.MuiSlider-mark {
  background-color: white !important;
}

.MuiSlider-markActive {
  background-color: #2BAEB3 !important;
}

.MuiButton-label {
  font-size: 14pt;
  font-family: 'Montserrat', sans-serif !important;
}

.MuiButton-root{
  font-family: 'Montserrat', sans-serif !important;
  padding: 3pt !important;
}

.MuiButton-containedSecondary{
  font-family: 'Montserrat', sans-serif !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #c5c5c5 !important;
  border-width: 2px !important;
  height: 50px;
}

.MuiOutlinedInput-root{
  border-radius: 7px !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #c5c5c5 !important;
  border-width: 2px;
}

.MuiButton-containedPrimary{
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #2BAEB3 !important;
}

.MuiButton-containedSecondary {
  color: #fff;
  background-color: #2BAEB3 !important;
}

.MuiButton-contained.Mui-disabled {
  color: rgba(255, 255, 255, 0.445) !important;
}

.MuiLink-underlineHover {
  color: #2BAEB3 !important;
  text-decoration: underline !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #2BAEB3 !important;
}

.MuiInputLabel-formControl {
  top: 0px !important;
  left: -72px !important;
  position: absolute !important;
  background: white !important;
  place-self: baseline !important;
  transform-origin: top left !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #2BAEB3 !important;
}

.MuiFormLabel-root {
  color: #2BAEB3 !important;
  font-size: 16pt !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
  color: #707070 !important;
  padding-right: 32px !important;
}

.MuiSelect-select:focus{
  background-color: #fff !important;
}

.MuiOutlinedInput-input {
  padding: 13px 8px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(80px, -9px) scale(0.7) !important;
  letter-spacing: 0em !important;
  font-family: 'Montserrat', sans-serif !important;
}

.MuiMenuItem-root {
  color: #707070 !important;
  font-size: 16pt !important;
  font-family: 'Montserrat', sans-serif !important;
}

.MuiInputBase-input{
  color: #707070 !important;
  font-size: 16pt !important;
  font-family: 'Montserrat', sans-serif !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.confirm input {
  width: 100%;
  color: #707070;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16pt;
}

.btn-atras {
  color: #2BAEB3 !important;
  box-shadow: none;
  background-color: white !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
  text-transform: none !important; 
  width: 14rem; 
  border-radius: 100px !important; 
}

.btn-editar {
  color: #2BAEB3 !important;
  box-shadow: none;
  background-color: white !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
  text-transform: none !important; 
  width: 14rem; 
  border-radius: 100px !important; 
  margin-bottom: 10pt !important;
}

.btn-cellphone {
  color: #2BAEB3 !important;
  box-shadow: none;
  background-color: white !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
  text-transform: none !important;
  width: 20rem;
  border-radius: 100px !important;
  margin-bottom: 10pt !important;
  margin-left: 10pt !important;
  justify-content: space-evenly !important;
}


.btn-validacion {
  color: #ffffff !important;
  box-shadow: none;
  background-color: #2BAEB3!important;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
  text-transform: none !important; 
  width: 14rem; 
  border-radius: 100px !important; 
}

.btn-confirmar {
  color: #ffffff !important;
  box-shadow: none;
  background-color: #2BAEB3 !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
  text-transform: none !important; 
  width: 14rem; 
  border-radius: 100px !important; 
  margin-bottom: 10pt !important;
  margin-left: 50pt !important;
}

#typography {
  padding-left: 20px;
  padding-bottom: 10px;
  font-size: 11pt !important;
  font-family: 'Montserrat', sans-serif !important;
  color: #707070 ;
}

#typography2 {
  padding-left: 20px;
  font-size: 11pt !important;
  font-family: 'Montserrat', sans-serif !important;
  color: #707070 ;
}

#acept-politics {
  padding-top: 5px;
}

#dir-numero {
  width: 35px;
}

#espacio {
  width: 6px;
}

.input-person {
  padding: 0.1px 1px 25px 1px;
}

.check-dataPerson-1 {
  position: fixed;
  left: 1%;
}

.check-dataPerson {
  position: fixed;
  left: 159%;
}
.label-check {
  font-size: 9px;
}

#input-person {
  margin-right: 90px;
}

.input-person-select {
  padding: 2px 50px 15px 10px;
  box-shadow: 5px 5px 5px -6px rgb(0 0 0 / 50%);
  border-radius: 15px;
}

.direccion-codigo {
  width: 80px;
  position: fixed;
  left: 10px;
}

.direccion-numero {
  width: 80px;
  position: fixed;
  left: 18px;
}

#input-direccion {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 390px;
}

#fecha-hora {
  padding: 10px;
  display: grid;
}

#select-contac {
  padding: 10px;
  display: grid;
}

#select-contac {
  padding-top: 12px;
}

#alert-comerce {
  font-size: calc(0.6em + 0vw);
  color: crimson;
  padding: 0px;
}

.title-Person {
  text-align: "center";
  margin-bottom: 20px;
}

.confirm section select {
  border: 0px;
  margin-right: 2px;
  /*	 border-bottom: 1px solid rgba(41, 87, 161, 0.9);*/
  color: #000;
  -moz-appearance: menulist;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  /*appearance: menulist;*/
}
.confirm section select:disabled {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}
.confirm section select:hover {
  background: #fff;
}
.confirm .icon-edit {
  background-color: #f29f05 ;
  border-radius: 50px;
  border: 0px;
  color: white;
}
.confirm .icon-edit:hover {
  background-color: #fff;
  color: #f29f05 ;
}
.confirm .check {
  text-align: left;
  padding: 0px 0px 5px;
  font-size: 10pt;
}
.confirm #politics {
  text-align: left;
  padding: 15px 0px 5px 0px;
  color: #2BAEB3;
  text-decoration: underline;
}
.confirm #aceptoTerminos {
  display: inline;
  /* width: auto;*/
}
.confirm .from-input {
  text-align: left;
  padding: 0px 0px 5px;
  font-size: 10pt;
  margin-bottom: 5px;
  border-radius: 10px;
}
.confirm .from-input span {
  padding: inherit;
  /*	 color: rgba(41, 87, 161, 0.9);*/
  font-size: 10pt;
}
.confirm .from-input .form-control:disabled,
.confirm .from-input .form-control {
  background-color: white;
  border: 0px;
}
.confirm .from-input label {
  padding: 10px;
  display: inline;
  color: #6cd0d6;
  font-size: 15px;
}
.confirm .from-input .requerid-fiel {
  padding: 0;
}
.confirm .from-input input {
  border: 0px;
  /*border-bottom: 1px solid rgba(41, 87, 161, 0.9);*/
  margin: 0px;
  padding: 0px 5px;
}
.confirm,
.confirm {
  padding-top: 20px;
  color: #2BAEB3;
  font-size: 18pt;
}
.confirm p {
  color: #707070;
  font-size: 13pt !important;
  margin-right: -25pt;
  margin-top: 2pt;
}


.textMiddleFirm a {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline !important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1; /* Firefox */
}

.confirm p.selected {
  color: #707070;
  font-size: 14pt !important;
  margin-right: -25pt;
  margin-top: 2pt;
}
.confirm .cuota-cal {
  color: #2BAEB3;
  font-size: 18pt;
}
.confirm .card-text {
  padding-top: 10px;
}
.confirm .card-header {
  font-weight: bold;
}
.confirm .section-bill {
  /* border: 1px solid rgba(41, 87, 161, 0.5);*/
  margin-bottom: 8px;
  -webkit-box-shadow: 0px 5px 6px 2px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 5px 6px 2px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 5px 6px 2px rgba(0, 0, 0, 0.37);
}
.confirm .section-bill:hover,
.confirm .section-bill h5:hover {
  cursor: pointer;
}
.confirm .select-bill {
  /* background-color: rgba(41, 87, 161, 0.9);*/
  color: white;
}
.confirm .select-bill,
.confirm .select-bill span {
  color: white;
}
#card-section section {
  margin-bottom: 35px;
}
#card-section form {
  margin: auto;
  border: 1px solid #2BAEB3;
  -webkit-box-shadow: 0px 5px 6px 2px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 5px 6px 2px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 5px 6px 2px rgba(0, 0, 0, 0.37);
}
#card-section form .form-control {
  border: 0px;
  border-radius: 0px;
  color: #2BAEB3;
}
#card-section form .row {
  margin: 0px;
}
#card-section form .col-1 {
  background-color: white;
  margin: 0px;
  padding: 0.5rem 0px;
}
#card-section form span {
  color: #2BAEB3;
}
#card-section .rccs__card {
  max-width: 100%;
}
#card-section .form-group,
#card-section .col-5 {
  margin: 0px;
  padding: 0px;
  background-color: white;
}
#card-section .col-card-6-left {
  padding-left: 0px;
}
#card-section .col-card-6-right {
  padding-right: 0px;
}
#payment {
  padding-top: 22px;
}
#payment .section {
  margin-bottom: 30px;
  background-color: white;
  color: #2BAEB3;
  font-size: 14pt;
  padding: 10px 0px;
}
#payment #default {
  height: auto;
  padding: 1.6em;
  margin-bottom: 40px;
  background-color: white;
  border: 1px solid #2BAEB3;
  -webkit-box-shadow: 0px 5px 6px 2px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 5px 6px 2px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 5px 6px 2px rgba(0, 0, 0, 0.37);
}
#payment .pse img {
  width: 4.5em;
}
.progressbar {
  display: block;
  margin: 20px auto;
  color: white;
}

.titleDocument {
  margin-left: 5pt !important;
  margin-bottom: -20px;
  font-size: 18pt;
  font-weight: 100;
}

.titleCellphone {
  margin-left: -2pt !important;
  margin-bottom: -20px;
  font-size: 18pt;
  font-weight: 100;
}

.titleEmail {
  margin-left: 6pt !important;
  margin-bottom: -20px;
  font-size: 18pt;
  font-weight: 100;
}

.titleNotificacion {
  margin-left: 7pt !important;
  margin-bottom: -20px;
  font-size: 16pt;
  text-align: center;
}

.progressbar div {
  font-size: medium;
  display: inline;
  padding: 8px 13px;
  margin: 20px;
  border-radius: 50%;
  
}
.inactive {
  background-color: #c5c5c5;
}
.active {
  background-color: #2BAEB3;
  border: 2px solid #fff;
  color: white;
}
.Loading {
  display: flex;
}
.msg-error {
  font-size: x-large;
  color: #2BAEB3;
}
.footer {
  text-align: center;
}
.footer p {
  font-size: 12px;;
  color: #000000;
  /* color: #fff d4;*/
}

.info{
  position:relative;
  max-width: 100%;
  margin-left: 25px;
  margin-right: 25px;
}

.links {
  color: #2BAEB3;
  display: inline-block;
}

.radiun-30 {
  border-radius: 30%;
}


.color-signo{
  margin-left: -10pt;
  margin-top: 12pt;
}

.color-signo2{
  color:#c6c6c6 !important;
}

.color-signo2 .selected{
  color:#707070 !important;
}
a {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: none;
}


a:hover {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

#list-select-plan {
  padding: 1px;
  padding-left: 10px;
 
}
#list-select-plan .select-plan {
  max-width: 100%;
  margin: 12px;
  margin-bottom: 8px;
  text-align: center;
  
 
}
#list-select-plan .select-plan .form-control {
  border: 4px solid #ffffff;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-right: -22px !important;
  margin: -32px;
  padding: 0px;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
  border-radius: 10pt;
}

.MuiInputBase-root.Mui-disabled {
  background: #dfdfdf !important;
}

#list-select-plan .select-plan {
  color: #4d4d4d;
}

#list-select-plan .select-plan2 {
  color: #f29f05 ;
}
#list-select-plan .select-plan .text-p {
  font-size: 14pt;
  color: #676767;
}
#list-select-plan .select-plan .text-b {
  font-size: 1.2em;
  color: #000;
}
#list-select-plan .b-plan {
  color: #000;
  font-size: 14px;
}

#list-select-plan .select-plan .linea {
  background-color: #707070; 
  height: 0.5pt; 
  margin: 6pt; 
  margin-top: 5pt;
}

#list-select-plan .select-plan .punto {
  color: #2BAEB3; 
  font-size: 10pt;
}

.container-form {
  width: auto;
  min-height: 50vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.container-text {
  min-height: 50vh;
  display: block;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-right: 28px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.container-top {
  min-height: 40px;
  display: block;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  
}

.label__color {
  color: #2BAEB3 !important;
  font-size: 16pt !important;
  font-family: 'Montserrat', sans-serif !important;
}


.multilineColor{
  color:red;
}

#form-select {
  width: 100%;
}

#form-select-dir {
  width: 61%;
}

.form-control.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0);
}
#form-title {
  font-weight: bold;
}
.validation {
  border-radius: 20px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
.select__indicator {
  display: none;
}
@media only screen and (min-width: 600px) {
  /* For tablets: */
  .col-s-1 {
    width: 8.33%;
  }
  .col-s-2 {
    width: 16.66%;
  }
  .col-s-3 {
    width: 25%;
  }
  .col-s-4 {
    width: 33.33%;
  }
  .col-s-5 {
    width: 41.66%;
  }
  .col-s-6 {
    width: 50%;
  }
  .col-s-7 {
    width: 58.33%;
  }
  .col-s-8 {
    width: 66.66%;
  }
  .col-s-9 {
    width: 75%;
  }
  .col-s-10 {
    width: 83.33%;
  }
  .col-s-11 {
    width: 91.66%;
  }
  .col-s-12 {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {
    width: 8.33%;
  }
  .col-2 {
    width: 16.66%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33%;
  }
  .col-5 {
    width: 41.66%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.33%;
  }
  .col-8 {
    width: 66.66%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.33%;
  }
  .col-11 {
    width: 91.66%;
  }
  .col-12 {
    width: 100%;
  }
  .container-form {
    width: 60vh;
    margin: auto;
  }
  .container-text {
    width: 60vh;
    margin: auto;
  }
}
@media only screen and (max-width: 458px) {
  .confirm {
    font-size: 14pt;
  }
  #list-select-plan .select-plan .text-p {
    font-size: 10pt !important;
    color: #676767;
  }


  #list-select-plan .select-plan .text-b {
    font-size: 11pt;
  }

  .progressbar {
    display: block;
    margin: 17px auto;
  }
  .progressbar div {
    font-size: small;
    display: inline;
    padding: 6px 10px;
    /*margin: 20px;*/
    border-radius: 50%;
  }

  .logoPicture2 {
    margin-left: 0% !important;
  }

  #form-select-dir {
    width: 61%;
  }
  #list-select-plan .b-plan {
    font-size: 11px;
  }
  .confirm .from-input label {
    font-size: 13px;
  }

  .textPreguntas{
    text-align: justify;
    font-size: 14px;
    color:#707070;
    margin-top:3px;
    margin-left: 4pt;
  }

  .textPreguntas2{
    text-align: justify;
    font-size: 14px;
    color:#707070;
    margin-top:3px;
    margin-left: 4pt;
  }

  .radioButon{
    text-align: right !important; 
    margin-bottom:20px !important; 
    margin-top:1px !important;
    margin-left: 57pt !important; 
    display: row !important;
  }
}
@media only screen and (max-width: 370px) {
  .confirm {
    font-size: 11pt;
  }
  #list-select-plan .select-plan .text-p {
    font-size: 9pt;
    color: #676767;
  }
  #list-select-plan .select-plan .text-b {
    font-size: 1.1em;
  }
  .text-planes {
    font-size: 16px;
  }
  .progressbar {
    display: block;
    margin: 10px auto;
  }
  .progressbar div {
    font-size: small;
    display: inline;
    padding: 4px 8px;
    /* margin: 20px;*/
    border-radius: 50%;
  }
  #form-select-dir {
    width: 65%;
  }

  .logoNotificacion {
    margin-bottom: -20px;
  }

  .logoNotificacion2 {
    margin-bottom: -20px;
    margin-left: -10pt;
  }

  .logoCellphone{
    margin-bottom: -20px;
  }

  .logoDocument {
    margin-bottom: -20px;
  }

  .titleDocument {
    margin-left: 5pt !important;
    margin-bottom: -20px;
  }

  .titleCellphone {
    margin-left: -2pt !important;
    margin-bottom: -20px;
    font-size: 14pt;
  }

  .titleEmail {
    margin-left: 6pt !important;
    margin-bottom: -20px;
    font-size: 14pt;
  }

  .titleNotificacion {
    margin-left: 7pt !important;
    margin-bottom: -20px;
    font-size: 16pt;
    text-align: center;
  }

  .textNotificacion {
    margin-left: 13pt;
  }

  #input-select {
    font-size: 13pt;
  }
  #list-select-plan .b-plan {
    font-size: 11px;
  }
  .confirm .from-input label {
    font-size: 11px;
  }

  .loadingPerson {
    margin-left: 42% !important;
    margin-top: 65% !important;
    position: absolute;
    opacity: 2;
    z-index:3;
  }
  
  .loadingTextPerson {
    margin-left: 6% !important;
    opacity: 2;
    z-index:3 !important;
    text-align: center;
  }

  .loading-creat2 {
    margin-left: 40% !important;
    opacity: 1;
    z-index:3 !important;
  }

  .btn-confirmar {
    margin-left: 10pt !important;
  }

}

.modal {
  margin: 0 auto;
  width: 70%;
  max-width: 375px;
  margin-top: 120px;
  border-radius: 20px;
  background-color: white;
  border: 2px soild #000;
  box-shadow: 5px 5px 10px black;
  padding: 15px 28px 24px;
}

.modalNotificaion {
  margin: 0 auto;
  width: 70%;
  max-width: 375px;
  margin-top: 120px;
  border-radius: 20px;
  background-color: white;
  border: 2px soild #000;
  box-shadow: 5px 5px 10px black;
  padding: 15px 28px 24px;
}

.modalDocument {
  margin: 0 auto;
  width: 70%;
  max-width: 375px;
  margin-top: 120px;
  border-radius: 20px;
  background-color: white;
  border: 2px soild #000;
  box-shadow: 5px 5px 10px black;
  padding: 15px 28px 24px;
}

.MuiFormHelperText-contained {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.modalCellphone{
  margin: 0 auto;
  width: 70%;
  max-width: 375px;
  margin-top: 120px;
  border-radius: 20px;
  background-color: white;
  border: 2px soild #000;
  box-shadow: 5px 5px 10px black;
  padding: 15px 28px 24px;
}

.modalEmail{
  margin: 0 auto;
  width: 70%;
  max-width: 375px;
  margin-top: 120px;
  border-radius: 20px;
  background-color: white;
  border: 2px soild #000;
  box-shadow: 5px 5px 10px black;
  padding: 15px 28px 24px;
}

.modalDiv {
  display: flex;
  flex-wrap: wrap;
}

.modal .div-1 {
  padding: 2px;
  padding-bottom: 1px;
  box-shadow: -13px 20px 0px -18px rgb(0, 0, 0);
  text-align: center;
}

.modal h6 {
  color: #495057;
}

.button-modal {
  position: relative;
  left: 10%;
  width: 80%;
  border-radius: 100px !important;
}

.loading-creat {
  margin-left: 42%;
  margin-top: 5%;
  opacity: 1;
}

.loading-creat2 {
  margin-bottom: 5%;
  margin-left: 42%;
  opacity: 1;
}

.loadingPerson {
  margin-left: 20%;
  margin-top: 40%;
  position: absolute;
  opacity: 2;
  z-index:3;
}

.loadingTextPerson {
  margin-left: 8%;
  opacity: 2;
  z-index:3;
}

#select-producto {
  font-size: calc(0.5em + 0.5vw);
}

#grid-multi {
  padding-top: 15px;
  margin-left: 4px;
}

#select-contac {
  font-size: calc(0.7em + 0.5vw);
  text-align: center;
}

#direccion-select {
  font-size: calc(1em + 0vw);
}

.index-simulator {
  max-width: 400px;
  padding: 0;
  margin: 0 auto;
}
