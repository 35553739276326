.boxPrestapolisDEV {
  min-height: 30px;
background: rgb(188, 162, 0);
  background: radial-gradient(
    circle,
    rgba(188, 162, 0, 1) 0%,
    rgba(255, 103, 0, 1) 100%
  );
}

.boxPrestapolis {
  min-height: 30px;
  background: rgb(43, 174, 179);
  background: radial-gradient(
    circle,
    rgba(43, 174, 179, 1) 0%,
    rgba(43, 174, 179, 1) 100%
  );
}

.boxPrestapolisQA {
  min-height: 30px;
  background: rgb(77, 75, 70);
  background: radial-gradient(circle, rgb(32, 30, 30) 0%, rgb(46, 43, 43) 100%);
}

.boxPrestapolisSTG {
  min-height: 30px;
  background: rgb(21, 141, 27);
  background: radial-gradient(
    circle,
    rgba(21, 141, 27, 1) 0%,
    rgba(21, 141, 27, 1) 100%
  );
}

.logoPrestapolis {
  margin-left: -130px;
  margin-bottom: 20px;
}

.logoCentral{
  margin-right: 60px; 
  margin-top: -10px; 
  margin-bottom: -50px;
}


.logoCentralSad{
  margin-right: 60px; 
  margin-top: -10px; 
  margin-bottom: -50px;
}

.logoSad {
  width: 10rem;
  margin-left: 38%;
  margin-bottom: 20px;
}

.selectOption{
  border: 4px solid #ffffff;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-right: -22px !important;
    margin: -32px;
    padding: 0px;
    -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
    border-radius: 10pt;
}
.imgLogo {
  position: relative;
  max-width: 100%;
  margin-top: -5%;
  width: 250px;
  margin-left: 15pt;
}

.logoNegociacion {
  width: 10rem;
  margin-left: 29%;
  margin-bottom: -40px;
}

.textInicio2 {
  position: relative;
  max-width: 89%;
  margin-left: 0.3em;
  color: #2BAEB3;
  font-size: 1rem;
}


@media (max-width: 414px) { 

  .logoSad {
    width: 160px;
    max-height: 375px;
    margin-left: 35%;
  }

  .logoNegociacion {
    width: 8rem;
    margin-left: 24%;
    margin-bottom: -40px;
  }

  .logoPrestapolis {
    margin-top: -20pt;
    margin-left: 40px;
    margin-bottom: 20px;
  }

  .logoCentralSad{
    margin-right: 80px; 
    margin-top: -10px; 
    margin-bottom: -50px;
  }

  .textContenido{
    position: relative;
    max-width: 89% !important;
    margin-left: 1em !important;
    color: #707070;
    font-size: 10pt !important;
  }

  .textInicio{
    position: relative;
    max-width: 89%;
    margin-left: 0.7em !important;
    color: #2BAEB3;
    font-size: 18pt !important;
  }

  .textInicio2 {
    position: relative;
    max-width: 89%;
    margin-left: 0.3em;
    color: #2BAEB3;
    font-size: 1rem;
  }

  .textClausulas{
    position: relative;
    max-width: 89%;
    margin-left: 1.6em !important;
    color: #ffffff;
    font-size: 8pt !important;
  }

  .clausulas {
    background-color: #2BAEB3;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-right: 6px !important;
    padding: 0px;
    border-radius: 10pt;
    width: 100% !important;
  }

}

.textInicio{
  position: relative;
  max-width: 89%;
  margin-left: 1em;
  color: #2BAEB3;
  font-size: 20pt;
}

.textContenido{
  position: relative;
  max-width: 89%;
  margin-left: 1em;
  color: #707070;
  font-size: 15pt;
  text-align: justify;
}

.clausulas {
  background-color: #2BAEB3;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 26px;
  margin-left: 1px;
  padding: 0px;
  border-radius: 10pt;
  width: 100%;
}

.punto {
  color: #FF6700; 
  font-size: 0.7em !important;
}

.textClausulas{
  position: relative;
  max-width: 89%;
  margin-left: 1em;
  color: #ffffff;
  font-size: 15pt;
  text-align: justify;
}

.linksWhatsapp {
  color: #24b611;
  display: inline-block;
}

.marginReject{
  max-width: 440px;
  padding: 20px;
  margin-bottom: 25px;
  margin-left: 25px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 10px 10px 5px 0px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
}