.boxPrestapolisDEV {
  min-height: 30px;
background: rgb(188, 162, 0);
  background: radial-gradient(
    circle,
    rgba(188, 162, 0, 1) 0%,
    rgba(188, 162, 0, 1) 100%
  );
}

.bR_jJ {
  align-items: center;
  display: flex;
  flex-direction: column;
  box-sizing: inherit;
}

.bR_s9 {
  left: 50%;
  position: absolute;
  top: 18%;
  transform: translate(-50%,-50%);
  transform-origin: 0 80%;
  transition: transform .5s ease-in;
  width: 6%;
  z-index: 20;
}

.bR_tb {
  background: rgb(131, 196, 131);
  border-radius: 50%;
  height: 1rem;
  left: 49.5%;
  position: absolute;
  top: 16%;
  transition: transform .5s ease-in;
  width: 1rem;
  z-index: 20;
}

.boxPrestapolis {
  min-height: 30px;
  background: rgb(43, 174, 179);
  background: radial-gradient(
    circle,
    rgba(43, 174, 179, 1) 0%,
    rgba(43, 174, 179, 1) 100%
  );
}

.boxPrestapolisQA {
  min-height: 30px;
  background: rgb(77, 75, 70);
  background: radial-gradient(circle, rgb(32, 30, 30) 0%, rgb(46, 43, 43) 100%);
}

.boxPrestapolisSTG {
  min-height: 30px;
  background: rgb(21, 141, 27);
  background: radial-gradient(
    circle,
    rgba(21, 141, 27, 1) 0%,
    rgba(21, 141, 27, 1) 100%
  );
}

.logoPrestapolis {
  margin-left: -130px;
  margin-bottom: 20px;
}

.logoCentral{
  margin-right: 60px; 
  margin-top: -10px; 
  margin-bottom: -50px;
}


.logoCentralSad{
  margin-right: 60px; 
  margin-top: -10px; 
  margin-bottom: -50px;
}

.logoSad {
  width: 10rem;
  margin-left: 38%;
  margin-bottom: 20px;
}

.imgLogo {
  position: relative;
  max-width: 100%;
  margin-top: -5%;
  width: 250px;
  margin-left: 15pt;
}

@media (max-width: 414px) { 

  .logoSad {
    width: 160px;
    max-height: 375px;
    margin-left: 35%;
  }

  .logoPrestapolis {
    margin-top: -20pt;
    margin-left: 40px;
    margin-bottom: 20px;
  }

  .logoCentralSad{
    margin-right: 80px; 
    margin-top: -10px; 
    margin-bottom: -50px;
  }

  .textContenidoApproved{
    position: relative;
    max-width: 89% !important;
    margin-left: 1em !important;
    color: #2BAEB3;
    font-size: 13pt !important;
  }

  .textContenidoApproved2{
    position: relative;
    max-width: 89% !important;
    margin-left: 1em !important;
    color: #707070;
    font-size: 13pt !important;
  }

  .textMiddleApproved{
    position: relative;
    max-width: 89% !important;
    margin-left: 1em !important;
    color: #707070;
    font-size: 9pt !important;
  }

  .textInicioApproved{
    position: relative;
    max-width: 89%;
    margin-left: 0.7em !important;
    color: #2BAEB3;
    font-size: 18pt !important;
  }

  .textClausulasApproved{
    position: relative;
    max-width: 89%;
    margin-left: 1.6em !important;
    color: #707070;
    font-size: 10pt !important;
  }

  .textClausulasApproved2{
    position: relative;
    max-width: 89%;
    margin-right: -0.6em !important;
    color: #707070;
    font-size: 10pt !important;
  }

  .textClausulas2{
    position: relative;
    max-width: 89%;
    margin-left: 1.1em !important;
    color: #ffffff;
    font-size: 9.5pt !important;
  }

  .clausulasApproved {
    background-color: #ffffff;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-right: 6px !important;
    padding: 0px;
    border-radius: 10pt;
    -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
    width: 100% !important;
  }

  .bR_jJ {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .bR_tb {
    background: rgb(131, 196, 131);
    border-radius: 50%;
    height: 1rem;
    left: 43%;
    position: absolute;
    top: 24%;
    transition: -webkit-transform .5s ease-in;
    transition: transform .5s ease-in;
    transition: transform .5s ease-in,-webkit-transform .5s ease-in;
    width: 1rem;
    z-index: 20;
  }
  

}

.textInicioApproved{
  position: relative;
  max-width: 89%;
  margin-left: 1em;
  color: #2BAEB3;
  font-size: 20pt;
}

.textContenidoApproved{
  position: relative;
  max-width: 89%;
  margin-left: 1.2em;
  color: #2BAEB3;
  font-size: 16pt;
  text-align: center;
}

.textContenidoApproved2{
  position: relative;
  max-width: 89%;
  margin-left: 1em;
  color: #707070;
  font-size: 13pt;
  text-align: center;
}

.textMiddleApproved{
  position: relative;
  max-width: 89%;
  margin-left: 1em;
  color: #707070;
  font-size: 11pt;
  text-align: center;
}

.clausulasApproved {
  background-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 26px;
  margin-left: 1px;
  padding: 0px;
  border-radius: 10pt;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%) !important;
  width: 100%;
}

.punto {
  color: #F29F05; 
  font-size: 0.7em !important;
}

.textClausulasApproved{
  position: relative;
  max-width: 89%;
  margin-left: 0.4em !important;
  color: #707070;
  font-size: 12pt;
  text-align: initial;
}

.textClausulasApproved2{
  position: relative;
  max-width: 89%;
  margin-left: 1em;
  color: #707070;
  font-size: 12pt;
  text-align: end;
}

.textClausulas2{
  margin-top: -5pt;
  margin-bottom: 1pt;
  position: relative;
  max-width: 89%;
  margin-left: 1em;
  color: #ffffff;
  font-size: 12pt;
  text-align: justify;
}

.linksWhatsapp {
  color: #24b611;
  display: inline-block;
}

.marginAproved{
  max-width: 440px;
  padding: 20px;
  margin-bottom: 25px;
  margin-left: 25px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 10px 10px 5px 0px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
}