
.inicio-body{
    padding-top: 0.5%;
    padding-left: 25%;
}


img{
    max-width: 920px;
    max-height: 920px;
}

@media (max-width: 1024px) { 

    img{
        max-width: 900px;
        max-height: 900x;
        margin-left: 10%;
    }

    
    .inicio-body{
        padding-top: 0.5%;
        padding-left: 3px;
    }


}

@media (max-width: 414px) { 

    img{
        max-width: 375px;
        max-height: 375px;
        margin-left: 8%;
    }

    
    .inicio-body{
        padding-top: 0.5%;
        padding-left: 3px;
    }


}

@media (max-width: 375px) { 

    img{
        max-width: 335px;
        max-height: 335px;
        
    }
    .inicio-body{
        padding-top: 0.5%;
        padding-left: 1%;
    }

}


@media (max-width: 320px) { 

    img{
        max-width: 280px;
        max-height: 300px;
        margin-left: 10%;
    }

    
    .inicio-body{
        padding-top: 0.5%;
        padding-left: 3px;
    }


}